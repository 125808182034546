import { fromPredicate } from "./option";

export type Nullable<T> = T | null;

export interface BVDataProps {
  ID: Nullable<number>;
  vlbv: number;
  vl10: number;
  srfimp: number;
  srfdec: number;
  nmbv: string;
  area: number;
}
export type BVPropKey = keyof BVDataProps;

export interface DataProps {
  id: number;
  prj: Nullable<string>;
  nm: Nullable<string>;
  bv: Nullable<string>;
  com: Nullable<number>;
  srf: Nullable<number>;
  bat: null;
  in: Nullable<number>;
  nap: null;
  pol: Nullable<number>;
  nat: Nullable<number>;
  giep: Nullable<string>;
  inf: Nullable<number>;
  dec: Nullable<number>;
  stat: Nullable<string>;
  vl20mm: Nullable<number>;
  vl20: Nullable<number>;
  srfimp: Nullable<number>;
  vl100mm: Nullable<number>;
  vl100: Nullable<number>;
  cont: Nullable<number>;
  prgiep: Nullable<number>;
  srfgiep: Nullable<number>;
  vlgiep: Nullable<number>;
  vlopp: Nullable<number>;
}
export type PropKey = keyof DataProps;

export type Lang = "fr" | "nl";
const isLang = (l: unknown): l is Lang =>
  typeof l === "string" && (l === "fr" || l === "nl");

export type Legend = "bv" | "com" | "giep" | "stat";
export const legends: Legend[] = ["bv", "com", "giep", "stat"];
export type Category =
  | "1000"
  | "1030"
  | "1040"
  | "1050"
  | "1060"
  | "1070"
  | "1080"
  | "1081"
  | "1082"
  | "1083"
  | "1090"
  | "1140"
  | "1150"
  | "1160"
  | "1170"
  | "1180"
  | "1190"
  | "1200"
  | "1210"
  | "brne"
  | "mol"
  | "mael"
  | "uk"
  | "wol"
  | "sen"
  | "noue"
  | "wadi"
  | "toit"
  | "desimp"
  | "cit"
  | "surf"
  | "rea"
  | "plan"
  | "encour"
  | "win"
  | "opdec"
  | "map"
  | "opp";

export const categories = () =>
  legends.reduce((acc, leg) => acc.concat(getCategory(leg)), [] as Category[]);

interface State {
  lang: Lang;
  projects: DataProps[];
  legend: Legend;
  category: Nullable<Category>;
  colors: string[];
  colorDefault: string;
  radiusDefault: number;
  categories: { [key in Legend]: string[] };
}

type StateKey = keyof State;

const state: State = {
  lang: fromPredicate<Lang>(isLang)((window as any).appLanguage).getOrElse(
    "fr"
  ),
  projects: [],
  legend: "bv",
  // colors: [
  //   "#2ba6ff",
  //   "#335496",
  //   "#42dd73",
  //   "#ce3943",
  //   "#df37c3",
  //   "#ff9c3a",
  //   "#373e46",
  //   "#c8e6cf",
  //   "#191760",
  //   "#b3e5b5",
  // ],
  category: null,
  colors: [
    "#D81B60",
    "#1E88E5",
    "#FF9C3A",
    "#165923",
    "#42DD73",
    "#FBA3EE",
    "#9325E0",
    "#186B73",
    "#BFE85E",
    "#2710EF",
    "#F5E6A5",
    "#B70707",
    "#64DEEC",
    "#001392",
    "#719945",
    "#9175F3",
    "#3D3029",
    "#740557",
    "#36A5A0",
  ],
  colorDefault: "#595959",
  radiusDefault: 6,
  categories: {
    bv: ["brne", "mol", "mael", "uk", "wol", "sen"] as Category[],
    com: [
      "1000",
      "1030",
      "1040",
      "1050",
      "1060",
      "1070",
      "1080",
      "1081",
      "1082",
      "1083",
      "1090",
      "1140",
      "1150",
      "1160",
      "1170",
      "1180",
      "1190",
      "1200",
      "1210",
    ] as Category[],
    giep: ["noue", "wadi", "toit", "desimp", "cit", "surf"] as Category[],
    stat: ["rea", "plan", "encour", "win", "opdec", "map", "opp"] as Category[],
  },
};
const observers: [key: StateKey, fn: () => void][] = [];

export const get = <K extends StateKey>(key: K): State[K] => state[key];

export const set = <K extends StateKey>(key: K, val: State[K]) => {
  console.log(key);
  state[key] = val;
  observers.forEach(([okey, fn]) => {
    if (key === okey) {
      fn();
    }
  });
};

export const observe = (key: StateKey, fn: () => void) =>
  observers.push([key, fn]);

export const getColor = (i: number) => {
  if (i < 0) {
    return get("colorDefault");
  }
  const colors = get("colors");
  const colorIndex = i % colors.length;
  return colors[colorIndex];
};

export const getCategory = (legend: Legend) =>
  get("categories")[legend] as Category[];
