import { fromNullable } from "./option";
import { get } from "./state";

export type Record = [key: string, fr: string, nl: string];

const records: Record[] = [
  ["__empty__", "", ""],

  // CATEGORIES communes
  ["1000", "Bruxelles", "Brussel"],
  ["1030", "Schaerbeek", "Schaarbeek"],
  ["1040", "Etterbeek", "Etterbeek"],
  ["1050", "Ixelles", "Elsene"],
  ["1060", "Saint-Gilles", "Sint-Gillies"],
  ["1070", "Anderlecht", "Anderlecht"],
  ["1080", "Molenbeek-Saint-Jean", "Sint-Jans-Molenbeek"],
  ["1081", "Koekelberg", "Koekelberg"],
  ["1082", "Berchem-Sainte-Agathe", "Sint-Agatha-Berchem"],
  ["1083", "Ganshoren", "Ganshoren"],
  ["1090", "Jette", "Jette"],
  ["1140", "Evere", "Evere"],
  ["1150", "Woluwé-Saint-Pierre", "Sint-Pieters-Woluwe"],
  ["1160", "Auderghem", "Oudergem"],
  ["1170", "Watermael-Boitsfort", "Watermaal-Bosvoorde"],
  ["1180", "Uccle", "Ukkel"],
  ["1190", "Forest", "Vorst"],
  ["1200", "Woluwé-Saint-Lambert", "Sint-Lambrechts-Woluwe"],
  ["1210", "Saint-Josse-ten-Noode", "Sint-Joost-ten-Node"],

  // CATEGORIES BV
  ["brne", "Broek-Neerpedebeek", "Broek-Neerpedebeek"],
  ["mol", "Molenbeek", "Molenbeek"],
  ["mael", "Nouveau Maelbeek", "Nieuwe Maalbeek"],
  ["uk", "Ukkel-Geleits-Verrew", "Ukkel-Geleits-Verrew"],
  ["wol", "Woluwe", "Woluwe"],
  ["sen", "Senne", "Zenne"],

  // CATEGORIES GIEP
  ["noue", "Noue", "Infiltratiegracht"],
  ["wadi", "Wadi", "Wadi"],
  ["toit", "Toiture Verte", "Groendak"],
  ["desimp", "Desimperméabilisation", "Ontharding"],
  ["cit", "Citerne", "Regenwaterput"],
  ["surf", "Surface accueillante", "Opslag oppervlakte"], // TODO

  // CATEGORIES STATUTS
  ["rea", "Réalisé", "Gerealiseerd"],
  ["plan", "Planifié", "Gepland"],
  ["encour", "En cours de recherche", "In onderzoek"],
  ["win", "Opportunité bigwin", "Opportuniteit bigwin"],
  ["opdec", "Opportunité etudededeconnexion", "Opportuniteit etudedeconnexion"],
  ["map", "Opportunité mapit", "Opportuniteit mapit"],
  ["opp", "Opportunité", "Opportuniteit"],

  // TITLE:
  ["generalities", "Généralités", "Algemeenheid"],
  [
    "vlbv-info",
    "Volume total du bassin versant",
    "Totaal volume van de stroombekken",
  ],
  [
    "aggregated_data",
    "Bilan sur l'ensemble de la sélection",
    "Data over het hele selectie",
  ], // nl TODO
  ["selected_projects", "Projets sélectionnés", "Geselecteerde projecten"],
  ["nb_projects", "Nombre de projetcs", "Aantal projecten"],

  // KEYS of projects:
  ["ID", "ID unique", "Unieke ID"],
  ["prj", "Projet", "Project"],
  ["nm", "Nom", "Naam"],
  ["bv", "Bassin versant", "Stroombekken"],
  ["com", "Commune", "Gemeente"],
  ["srf", "Surface totale (m²)", "Oppervlakte (m²)"],
  ["srfimp", "Surface imperméable (m²)", "Ondoorlaatbare oppervlakte (m²)"],
  ["bat", "Batiment (m²)", "Gebouwen (m²)"],
  ["in", "Zone d'inondation", "Overstromingsgebied"],
  ["nap", "Niveau moyen de la nappe phréatique", "Gemiddelde grondwaterstand"],
  ["pol", "Pollution du sol", "Grondverontreiniging"],
  ["nat", "Zone de nature protégé", "Beschermde natuurgebied"],
  ["giep", "Type de GIEP", "Soort GWB"],
  ["inf", "Capacité d'infiltration (mm/h)", "Infiltratie capaciteit (mm/u)"],
  ["vl20mm", "Volume TR20/4h (mm)", "Volume TR20/4h (mm)"],
  ["vl20", "Volume TR20/4h (m³)", "Volume TR20/4h (m³)"],
  ["vl100mm", "Volume TR100/4h (mm)", "Volume TR100/4h (mm)"],
  ["vl100", "Volume TR100/4h (m³)", "Volume TR100/4h (m³)"],
  ["cont", "Surface contribuante", "Toevoerende oppervlakte"],
  ["prgiep", "Profondeur GIEP", "Diepte GWB"],
  ["srfgiep", "Surface GIEP", "Oppervlakte GWB"],
  ["vlgiep", "Volume de GIEP", "Volume GWB"],
  ["vlopp", "Volume des opportunités", "Volume opportuniteiten"],
  ["comment", "Commentaire", "Commentaar"],

  ["dec", "Surface déconnectée (m²)", "Afgekoppelde oppervlakte (m²)"],
  ["stat", "Statut", "Statuut"],

  // KEYS of bv:
  ["Identification", "Nom français", "Nom Néerlandais"],
  ["id", "identifiant unique", "unieke id"],
  ["nmbv", "Nom bassin versant", "Naam stroombekken"],
  ["vol", "Volume totale d'eau (m³)", "Totaal water volume (m³)"],
  ["vlbv", "Volume totale d'eau (m³)", "Totaal water volume (m³)"],
  ["vl10", "Volume T10", "Volume T10"],
  ["srf", "Surface totale (m²)", "Totale opervlakte (m²)"],
  ["srfimp", "Surface imperméable (m²)", "Verharde oppervlakte (m²)"],
  ["srfdec", "Surface déconnectée (m²)", "Losgekoppelde oppervlakte (m²)"],

  // UTIL

  ["unknown", "Inconnu", "Onbekend"],
  ["else", "autre", "andere"],
  ["0", "Non", "Nee"],
  ["1", "Oui", "Ja"],
  ["in_average", "en moyenne", "gemiddeld"],
  ["remaining", "Reste", "Rest"],

  // HELPTEXT
  [
    "helptext:selectOnMap",
    "Pour accéder aux données, veuillez sélectionner un élément sur la carte ou une catégorie de légende",
    "Selecteer een element op de kaart of een legendeategorie om de gegevens weer te geven",
  ],
  [
    "helptext:noFeature",
    "Il n'y a pas d'élément dans cette catégorie. Pour accéder aux données, veuillez sélectionner un élément sur la carte ou une catégorie de légende",
    "Er is geen element in deze categorie. Selecteer een element op de kaart of een legendeategorie om de gegevens weer te geven",
  ],
];

export const tr = (key: string) =>
  fromNullable(records.find(([recKey]) => key === recKey))
    .map(([_, fr, nl]) => (get("lang") === "fr" ? fr : nl))
    .getOrElse(key);

export const trMulti = (key: string) =>
  key
    .split(",")
    .map((k) => tr(k.trim()))
    .join(", ");
