import { DIVWithClick, DIV, SPAN } from "./dom";
import { get, getCategory, getColor, Legend, PropKey } from "./state";
import { tr } from "./locale";

const renderNoCategory = () => {
  const picto = SPAN("color", "");
  picto.style.background = get("colorDefault");
  return DIV("legend-element unknown", picto, SPAN("label", tr("unknown")));
};

export const renderColorLegend = (
  legend: Legend,
  selectFeatures: (catKey: PropKey, catValue: string) => void
) =>
  DIV(
    "legend color",
    ...getCategory(legend).map((cat, i) => {
      const className = get("category") === cat ? "selected" : "unselected";
      const picto = SPAN("color", "");
      picto.style.backgroundColor = getColor(i);
      // picto.style.stroke = "1px";
      return DIVWithClick(
        `legend-element ${className}`,
        () => selectFeatures(legend, cat),
        picto,
        SPAN("label", tr(cat))
      );
    }),
    renderNoCategory()
  );
