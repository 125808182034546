import { isOption, Option } from "./option";

interface HTMLTags {
  a: HTMLAnchorElement;
  abbr: HTMLElement;
  address: HTMLElement;
  area: HTMLAreaElement;
  article: HTMLElement;
  aside: HTMLElement;
  audio: HTMLAudioElement;
  b: HTMLElement;
  base: HTMLBaseElement;
  bdi: HTMLElement;
  bdo: HTMLElement;
  big: HTMLElement;
  blockquote: HTMLElement;
  body: HTMLBodyElement;
  br: HTMLBRElement;
  button: HTMLButtonElement;
  canvas: HTMLCanvasElement;
  caption: HTMLElement;
  cite: HTMLElement;
  code: HTMLElement;
  col: HTMLTableColElement;
  colgroup: HTMLTableColElement;
  data: HTMLElement;
  datalist: HTMLDataListElement;
  dd: HTMLElement;
  del: HTMLElement;
  details: HTMLElement;
  dfn: HTMLElement;
  dialog: HTMLElement;
  div: HTMLDivElement;
  dl: HTMLDListElement;
  dt: HTMLElement;
  em: HTMLElement;
  embed: HTMLEmbedElement;
  fieldset: HTMLFieldSetElement;
  figcaption: HTMLElement;
  figure: HTMLElement;
  footer: HTMLElement;
  form: HTMLFormElement;
  h1: HTMLHeadingElement;
  h2: HTMLHeadingElement;
  h3: HTMLHeadingElement;
  h4: HTMLHeadingElement;
  h5: HTMLHeadingElement;
  h6: HTMLHeadingElement;
  head: HTMLElement;
  header: HTMLElement;
  hgroup: HTMLElement;
  hr: HTMLHRElement;
  html: HTMLHtmlElement;
  i: HTMLElement;
  iframe: HTMLIFrameElement;
  img: HTMLImageElement;
  input: HTMLInputElement;
  ins: HTMLModElement;
  kbd: HTMLElement;
  keygen: HTMLElement;
  label: HTMLLabelElement;
  legend: HTMLLegendElement;
  li: HTMLLIElement;
  link: HTMLLinkElement;
  main: HTMLElement;
  map: HTMLMapElement;
  mark: HTMLElement;
  menu: HTMLElement;
  menuitem: HTMLElement;
  meta: HTMLMetaElement;
  meter: HTMLElement;
  nav: HTMLElement;
  noscript: HTMLElement;
  // object: HTMLObjectElement;
  ol: HTMLOListElement;
  optgroup: HTMLOptGroupElement;
  option: HTMLOptionElement;
  output: HTMLElement;
  p: HTMLParagraphElement;
  param: HTMLParamElement;
  picture: HTMLElement;
  pre: HTMLPreElement;
  progress: HTMLProgressElement;
  q: HTMLQuoteElement;
  rp: HTMLElement;
  rt: HTMLElement;
  ruby: HTMLElement;
  s: HTMLElement;
  samp: HTMLElement;
  script: HTMLScriptElement;
  section: HTMLElement;
  select: HTMLSelectElement;
  small: HTMLElement;
  source: HTMLSourceElement;
  span: HTMLSpanElement;
  strong: HTMLElement;
  style: HTMLStyleElement;
  sub: HTMLElement;
  summary: HTMLElement;
  sup: HTMLElement;
  table: HTMLTableElement;
  tbody: HTMLTableSectionElement;
  td: HTMLTableDataCellElement;
  textarea: HTMLTextAreaElement;
  tfoot: HTMLTableSectionElement;
  th: HTMLTableHeaderCellElement;
  thead: HTMLTableSectionElement;
  time: HTMLElement;
  title: HTMLTitleElement;
  tr: HTMLTableRowElement;
  track: HTMLTrackElement;
  u: HTMLElement;
  ul: HTMLUListElement;
  var: HTMLElement;
  video: HTMLVideoElement;
  wbr: HTMLElement;
}

const create = <T extends keyof HTMLTags>(tag: T): HTMLTags[T] =>
  document.createElement(tag) as HTMLTags[T];

export const appendText = (text: string) => (node: Element) => {
  return node.appendChild(document.createTextNode(text));
};

export type AcNode =
  | HTMLElement
  | string
  | number
  | null
  | Option<HTMLElement>
  | SVGElement;

const createWithClass = <T extends keyof HTMLTags>(
  tag: T,
  className: string
) => {
  const node = create(tag);
  node.setAttribute("class", className);
  return node;
};

const createWithChildren = <T extends keyof HTMLTags>(
  tag: T,
  className: string,
  ns: AcNode[] | AcNode[][]
) => {
  const node = createWithClass(tag, className);
  ns.flat().forEach((n) => {
    if (typeof n === "number" || typeof n === "string") {
      appendText(n.toLocaleString())(node);
    } else if (isOption(n)) {
      n.map((n) => node.appendChild(n));
    } else if (n !== null) {
      node.appendChild(n);
    }
  });
  return node;
};

const activeClass = (a: boolean) => (a ? "selected" : "unselected");

export const DIV = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("div", className, ns);

export const DIVWithClick = (
  className: string,
  onClick: () => void,
  ...ns: AcNode[] | AcNode[][]
) => {
  const elem = DIV(className, ...ns);
  elem.addEventListener("click", onClick);
  return elem;
};

// type NodeAttribute = { [key: string]: string };
// export const DIVWithAttrs = (
//   attrs: NodeAttribute,
//   ...ns: AcNode[] | AcNode[][]
// ) => {
//   const elem = create("div");
//   Object.keys(attrs).map((k) => elem.setAttribute(k, attrs[k]));
//   ns.flat().forEach((n) => elem.appendChild(n));
//   return elem;
// };
export const SPANWithStyle = (
  className: string,
  style: string,
  ...ns: AcNode[] | AcNode[][]
) => {
  const elem = SPAN(className, ...ns);
  elem.setAttribute("style", style);
  return elem;
};

export const SPAN = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("span", className, ns);

export const LABEL = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("label", className, ns);

export const FIELDSET = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("fieldset", className, ns);

export const H1 = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("h1", className, ns);

export const H2 = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("h2", className, ns);

export const H3 = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("h3", className, ns);

export const UL = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("ul", className, ns);

export const LI = (className: string, ...ns: AcNode[] | AcNode[][]) =>
  createWithChildren("li", className, ns);

export const A = (
  className: string,
  url: string,
  ...ns: AcNode[] | AcNode[][]
) => {
  const a = createWithChildren("a", className, ns);
  a.href = url;
  a.target = "_blank";
  return a;
};

export const NODISPLAY = () => {
  const div = DIV("");
  div.style.display = "none";
  return div;
};

export const RADIOBTN =
  <T>(name: string, renderItem: (i: T) => AcNode, select: (i: T) => void) =>
  (className: string, options: T[], selected: T) => {
    const legend = createWithClass("legend", `switch__legend`);
    const item = DIV(
      `${className} switch__body`,
      options.map((o, i) => {
        const itemSelected = o === selected;
        const input = create("input");
        input.setAttribute("type", "radio");
        input.setAttribute("name", name);
        input.setAttribute("value", i.toString());

        if (selected === o) {
          input.setAttribute("checked", "true");
        }

        const elem = DIV(
          `switch__item item-${i + 1} ${activeClass(itemSelected)}`,
          LABEL(
            `switch__label`,
            input,
            SPAN("radio__control", SPAN("radio__control-inner")),
            renderItem(o)
          )
        );
        elem.addEventListener("click", () => select(o));
        return elem;
      })
    );
    return FIELDSET(`switch__fieldset`, legend, item);
  };

const uniq = <T>(xs: T[]) => Array.from(new Set(xs));

export function addClass(elem: Element, c: string) {
  const ecStr = elem.getAttribute("class");
  const ec = ecStr ? ecStr.split(" ") : [];
  ec.push(c);
  elem.setAttribute("class", uniq(ec).join(" "));
}

export function toggleClass(elem: Element, c: string) {
  const ecStr = elem.getAttribute("class");
  const ec = ecStr ? ecStr.split(" ") : [];
  if (ec.indexOf(c) < 0) {
    addClass(elem, c);
  } else {
    removeClass(elem, c);
  }
}

export function hasClass(elem: Element, c: string) {
  const ecStr = elem.getAttribute("class");
  const ec = ecStr ? ecStr.split(" ") : [];
  return !(ec.indexOf(c) < 0);
}

export function removeClass(elem: Element, c: string) {
  const ecStr = elem.getAttribute("class");
  const ec = ecStr ? ecStr.split(" ") : [];
  elem.setAttribute("class", ec.filter((cc) => cc !== c).join(" "));
}

export function emptyElement(elem: Node) {
  while (elem.firstChild) {
    removeElement(elem.firstChild);
  }
  return elem;
}

export function removeElement(elem: Node, keepChildren = false) {
  if (!keepChildren) {
    emptyElement(elem);
  }
  const parent = elem.parentNode;
  const evt = document.createEvent("CustomEvent");
  if (parent) {
    parent.removeChild(elem);
  }
  evt.initCustomEvent("remove", false, false, null);
  elem.dispatchEvent(evt);
  return elem;
}
