import { FeatureLike } from "ol/Feature";
import {
  DataProps,
  get,
  Legend,
  // getCategory,
  getColor,
  getCategory,
} from "./state";
import * as style from "ol/style";
import { fromNullable, Option } from "./option";
import { makePattern } from "./pattern";
import type { DataContext } from "./index";

export const polygon = (stroke: number, strokeColor: string, color: string) =>
  new style.Style({
    stroke: new style.Stroke({
      color: strokeColor,
      width: stroke,
    }),
    fill: new style.Fill({
      color,
    }),
  });

export const polygonWithHash = (
  // stroke: number,
  // strokeColor: string,
  color: string,
  hashStroke: number,
  hashColor: string
) => [
  new style.Style({
    fill: new style.Fill({
      color,
    }),
  }),
  new style.Style({
    // stroke: new style.Stroke({
    //   color: strokeColor,
    //   width: stroke,
    // }),
    fill: new style.Fill({
      color: makePattern(hashStroke, 45, hashColor),
    }),
  }),
];

const bvStyle = (props: Option<DataProps>, categories: string[]) => {
  const typeName = props.chain((p) => fromNullable(p["bv"])).getOrElse("");

  const cat = fromNullable(categories.find((c) => c === typeName));
  const index = cat.map((c) => categories.indexOf(c)).getOrElse(-1);
  const color = getColor(index);

  return polygon(0, color, color);
};

const comStyle = (props: Option<DataProps>, categories: string[]) => {
  const typeName = props
    .chain((p) => fromNullable(p["com"]).map((cp) => cp.toString()))
    .getOrElse("");

  const cat = fromNullable(categories.find((c) => c === typeName));
  const index = cat.map((c) => categories.indexOf(c)).getOrElse(-1);
  const color = getColor(index);

  return polygon(0, color, color);
};

const giepStyle = (props: Option<DataProps>, categories: string[]) =>
  props
    .chain((p) => fromNullable(p["giep"]))
    .fold(
      () => polygon(1, get("colorDefault"), get("colorDefault")),
      (typeName) => {
        const cat = categories.filter((c) => typeName.includes(c));
        const indexes = cat.map((c) => categories.indexOf(c));
        const colors = indexes.map((i) => getColor(i));
        if (colors.length === 1) {
          return polygon(0, colors[0], colors[0]);
        } else if (colors.length > 1) {
          return polygonWithHash(colors[0], 10, colors[1]);
        }
      }
    );

const statStyle = (props: Option<DataProps>, categories: string[]) => {
  const typeName = props.chain((p) => fromNullable(p["stat"])).getOrElse("");
  console.log(`STATUS prop: ${typeName}`);
  const cat = fromNullable(
    categories.find((c) => {
      console.log(`cat NAME: ${c}`);
      return c === typeName;
    })
  );
  const index = cat.map((c) => categories.indexOf(c)).getOrElse(-1);
  const color = getColor(index);

  return polygon(0, color, color);
};

export const polygonDefaultStyle = polygon(2, "grey", "grey");

export const vectorStyle =
  (key: Legend, dataContext: DataContext) => (f: FeatureLike) => {
    const categories = getCategory(key);
    const props: Option<DataProps> = dataContext.getProps(f);
    switch (key) {
      case "bv":
        return bvStyle(props, categories);
      case "com":
        return comStyle(props, categories);
      case "giep":
        return giepStyle(props, categories);
      case "stat":
        return statStyle(props, categories);
    }
  };

export const makeStyle = (dataContext: DataContext) => (f: FeatureLike) => {
  const s = vectorStyle(get("legend"), dataContext);
  return s(f);
};

export const selectStyle = (_f: FeatureLike) =>
  polygon(3, "#ed6b06", "#fce702");

export const bvLayerStyle = (f: FeatureLike) =>
  new style.Style({
    fill: new style.Fill({ color: "transparent" }),
    stroke: new style.Stroke({ color: "#299cc6", width: 1 }),
  });
