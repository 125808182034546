import Feature from "ol/Feature";
import { AcNode, DIV, H3, emptyElement } from "./dom";
import { DataProps, PropKey } from "./state";
import { tr, trMulti } from "./locale";
import { fromNullable, Option } from "./option";
import { tryNumber } from "./util";
import { CIRCLE, PATH, SVG, TEXT, arc, close, lineTo, moveTo } from "./svg";
import type { DataContext } from "./index";

type KeyTitle = "generalities";

type KeyGroup = { title: KeyTitle; keys: PropKey[] };

const KEYS: KeyGroup[] = [
  {
    title: "generalities",
    keys: [
      // "ID_1",
      // "prj",
      // "nm", // Nom
      "bv", // Bassin versant
      "com", // Commune
      "srf", // Surface totale
      "bat", // Dont batiment
      "in", // zone d'inondation
      "pol", //pollution du sol
      // "nap", // niveau nape phréatique
      "nat", //zone de nature protégée
      "inf", // capacité d'infiltration
      "stat", //statut
      "srfimp", //srf imp
      "cont", // surface contribuante
      "srfgiep", //surface giep
      "vlgiep", //volume giep
      // "prgiep",
      "vlopp", //volume des opportunités
      "giep",
      "dec", //surface déconnectée potentielle
      // "vl20mm",
      // "vl20",
      // "vl100mm",
      // "vl100",
    ],
  },
];

const renderKey = (key: PropKey): AcNode => DIV(`key ${key}`, tr(key));

type Base = string | number;
const renderBool = (key: PropKey, n: Base) => {
  const isTrue = n === 1;
  const color = isTrue ? "black" : "gray";
  const node = DIV(
    `kv ${isTrue} ${key}`,
    renderKey(key),
    DIV(`value ${typeof n}`, tr(n.toString()))
  );
  node.style.color = color;
  return node;
};

const renderString = (key: PropKey, s: Base) =>
  DIV(
    `kv ${key}`,
    renderKey(key),
    DIV(`value ${typeof s}`, trMulti(s.toString()))
  );

const renderNumber = (key: PropKey, n: Base) =>
  DIV(
    `kv ${key}`,
    renderKey(key),
    DIV(`value ${typeof n}`, n.toLocaleString("fr-BE"))
  );

type RenderType = "string" | "number" | "boolean";

export const renderType: { [key in PropKey]?: RenderType } = {
  id: "number",
  prj: "string",
  nm: "string",
  bv: "string",
  com: "string",
  srf: "number",
  bat: "string",
  in: "boolean",
  nap: "string",
  pol: "boolean",
  nat: "boolean",
  giep: "string",
  inf: "number",
  dec: "number",
  stat: "string",
  vl20mm: "number",
  vl20: "number",
  srfimp: "number",
  vl100mm: "number",
  vl100: "number",
  cont: "number",
  prgiep: "number",
  srfgiep: "number",
  vlgiep: "number",
  vlopp: "number",
};

const makePercentSurfConnect = (f: Feature, dataContext: DataContext) => {
  let c = dataContext.getFeatureProp(f, "srfimp").chain((c) => tryNumber(c));
  let d = dataContext.getFeatureProp(f, "dec").chain((d) => tryNumber(d));
  return c.chain((cont) => d.map((dec) => dec / cont));
};

const deg2rad = (d: number) => (d * Math.PI) / 180;

const polar2cartesian = (
  R: number,
  theta: number, //en degrés
  center: [number, number]
) => {
  const x = R * Math.cos(deg2rad(theta)) + center[0];
  const y = R * Math.sin(deg2rad(theta)) + center[1];
  return [x, y];
};

const svgArc = (percent: number, color: string, radius: number) => {
  const theta = 90 - (360 - (percent * 360) / 100);
  const center: [number, number] = [0, 0];
  const [sx, sy] = [0, -radius];
  const [ex, ey] = polar2cartesian(radius, theta, center);
  const offset = moveTo(sx, sy);
  const drawArc = arc(ex, ey, radius);
  const line = lineTo(0, 0);
  const path = PATH([offset, drawArc, line, close()], {
    fill: "white",
    "fill-opacity": "0.7",
  });
  const circle = CIRCLE(0, 0, radius, {
    fill: color,
  });
  const centerCircle = CIRCLE(0, 0, radius / 3, { fill: "white" });
  const centerText = TEXT(-20, 5, `${percent} %`, {
    class: "percent",
    "font-size": "22px",
    fill: color,
  });
  return SVG([circle, path, centerCircle, centerText], {
    viewBox: `${-radius} ${-radius} ${2 * radius} ${2 * radius}`,
  });
};

const displayPercentSurfConnect = (f: Feature, dataContext: DataContext) =>
  makePercentSurfConnect(f, dataContext).map((p) => {
    if (p < 100) {
      return DIV(
        "connection-info",
        `La surface de ruissellement est déconnectée à ${p} %.`,
        DIV("pie-chart", svgArc(p, "#4bafdd", 150))
      );
    } else if (p === 100) {
      return DIV(
        "connection-info",
        "La surface de ruissellement est entièrement déconnectée."
      );
    } else {
      return DIV(
        "connection-info",
        "La surface de ruissellement est entièrement déconnectée et la structure GIEP peut recevoir encore plus d’eau."
      );
    }
  });

const makeGroup = (
  keyGroup: KeyGroup,
  f: Feature,
  dataContext: DataContext
) => {
  // const title = H3("info-title", tr(keyGroup.title));
  const keys: AcNode[] = [];
  keyGroup.keys.forEach((k) =>
    makeItem(k, f, dataContext).map((i) => keys.push(i))
  );
  displayPercentSurfConnect(f, dataContext).map((dpsc) => keys.push(dpsc));
  return DIV("info-group", DIV("info-keys", keys));
};

const makeItem = (key: PropKey, f: Feature, dataContext: DataContext) => {
  const props: Option<DataProps> = dataContext.getProps(f);
  const value = props.chain((p) => fromNullable(p[key]));
  return value.map((v) => {
    switch (renderType[key]) {
      case "string":
        return renderString(key, v);
      case "number":
        return renderNumber(key, v);
      case "boolean":
        return renderBool(key, v);
      default:
        return renderString(key, v);
    }
  });
};

const renderMainInfo = (f: Feature, dataContext: DataContext) => {
  const props: Option<DataProps> = dataContext.getProps(f);

  const name = props.chain((p) => fromNullable(p.nm).map((n) => DIV("", n)));
  return H3(`main-info`, name);
};

export const featureInfoFactory =
  (parent: Element, dataContext: DataContext) => (features: Feature[]) => {
    emptyElement(parent);
    // parent.appendChild(H2("features-title", tr("selected_projects")));
    features.map((feature) => {
      const featureElem = DIV(
        "feature-wrapper",
        renderMainInfo(feature, dataContext)
      );
      KEYS.forEach((k) =>
        featureElem.appendChild(makeGroup(k, feature, dataContext))
      );
      parent.appendChild(featureElem);
    });
  };
