import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import GeoJSON, { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { fromLonLat } from "ol/proj";
import TileWMS from "ol/source/TileWMS";
import Select, { SelectEvent } from "ol/interaction/Select";
import { click } from "ol/events/condition";
import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import { BVDataProps, BVPropKey } from "./state";

import { DIV, emptyElement, RADIOBTN } from "./dom";
import {
  makeStyle,
  vectorStyle,
  selectStyle,
  polygonDefaultStyle,
  bvLayerStyle,
} from "./map-style";
import {
  get,
  set,
  observe,
  Legend,
  legends,
  PropKey,
  Category,
  categories,
  DataProps,
} from "./state";
import { renderColorLegend } from "./legend";
import { featuresInfoFactory } from "./summary";
import { featureInfoFactory } from "./indivi";

import { tr } from "./locale";
import { fromNullable, Option } from "./option";
import { tryNumber } from "./util";

const format = new GeoJSON({
  featureProjection: "EPSG:3857",
});

const legendSwitchButton = RADIOBTN(
  "switch-legend",
  (e: Legend) => DIV("legend-option", tr(e)),
  (e: Legend) => {
    set("legend", e);
    set("category", null);
  }
);

const renderSwitchLegend = () =>
  legendSwitchButton("switch-legend", legends, get("legend"));

const intro = () => DIV("intro", tr("helptext:selectOnMap"));
// const noFeatures = () => DIV("intro", tr("helptext:noFeature"));

export const updateInfo = (info: Element) => {
  emptyElement(info);
  // get("category") === null
  //   ? info.appendChild(intro())
  //   : info.appendChild(noFeatures());
  info.appendChild(intro());
};
const updateLegend = (
  legend: Element,
  selectFeatures: (catKey: PropKey, catValue: string) => void
) => {
  emptyElement(legend);
  legend.appendChild(
    DIV("legendContent", renderColorLegend(get("legend"), selectFeatures))
  );
};

export type DataContext = {
  getFeatureBV: (f: Feature) => Option<string | number>;
  getProps: (f: FeatureLike) => Option<DataProps>;
  getFeatureProp: (f: Feature, key: PropKey) => Option<string | number>;
  getFeatureID: (f: Feature) => Option<number>;
  getFeatureName: (f: Feature) => Option<string | number>;
  getBVProps: (bv: string) => Option<BVDataProps>;
  getBVProp: (bv: string, prop: BVPropKey) => Option<string | number>;
  isFromCategory: (
    feature: FeatureLike,
    catKey: PropKey,
    catValue: string
  ) => boolean;
};

const makeContext = (
  perimProjects: GeoJSONFeatureCollection,
  posProjects: GeoJSONFeatureCollection,
  watersheds: GeoJSONFeatureCollection
): DataContext => {
  const getFeatureProp = (f: Feature, key: PropKey) => {
    const props = getProps(f);
    return props.chain((p) => fromNullable(p[key]));
  };
  const getProps = (f: FeatureLike): Option<DataProps> => {
    const id = f.getId();
    return fromNullable(perimProjects.features.find((f) => f.id === id)).map(
      (f) => f.properties as DataProps
    );
  };

  const getFeatureBV = (f: Feature) => getFeatureProp(f, "bv");
  const getFeatureID = (f: Feature) =>
    getFeatureProp(f, "id").chain((id) => tryNumber(id));
  const getFeatureName = (f: Feature) => getFeatureProp(f, "nm");

  const getBVProps = (bv: string): Option<BVDataProps> =>
    fromNullable(
      watersheds.features.find((f) => f.properties?.nmbv === bv)
    ).map((f) => f.properties as BVDataProps);

  const getBVProp = (bv: string, prop: BVPropKey) =>
    getBVProps(bv).chain((p) => fromNullable(p[prop]));
  const isFromCategory = (
    feature: FeatureLike,
    catKey: PropKey,
    catValue: string
  ) =>
    getProps(feature)
      .chain((f) => fromNullable(f[catKey]))
      .map((fcat) => fcat.toString().includes(catValue))
      .getOrElse(false);

  return {
    getFeatureProp,
    getProps,
    getFeatureBV,
    getFeatureID,
    getFeatureName,
    getBVProps,
    getBVProp,
    isFromCategory,
  };
};

const main = (
  perimProjects: GeoJSONFeatureCollection,
  posProjects: GeoJSONFeatureCollection,
  watersheds: GeoJSONFeatureCollection
) => {
  /**
   * `posProjects` are projects with only a point, I leave it to you
   * to make a layer for it, and adjust those functions that look into projects. -pm
   */

  const dataContext = makeContext(perimProjects, posProjects, watersheds);

  const bvSource = new VectorSource({
    features: format.readFeatures(watersheds),
  });
  const bvLayer = new VectorLayer({
    source: bvSource,
    style: bvLayerStyle,
  });

  const vectorSource = new VectorSource({
    features: format.readFeatures(perimProjects).map((f) => {
      f.setId(f.get("id"));
      return f;
    }),
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: makeStyle(dataContext),
  });

  const select = new Select({
    condition: click,
    style: selectStyle,
    layers: [vectorLayer],
  });

  const getFeaturesFromCat = (catKey: PropKey, catValue: string) =>
    fromNullable(vectorLayer.getSource())
      .map((s) =>
        s
          .getFeatures()
          .filter((f) => dataContext.isFromCategory(f, catKey, catValue))
      )
      .getOrElse([]);

  const getFeatureFromId = (id: number) =>
    fromNullable(vectorLayer.getSource()).chain((s) =>
      fromNullable(s.getFeatures().find((f) => f.getId() == id))
    );

  const view = new View({
    center: fromLonLat([4.3252, 50.8801]),
    zoom: 14,
  });

  fromNullable(vectorLayer.getSource()).map((s) =>
    s
      .getFeatures()
      .map((f) =>
        console.log(
          `Feature: ${fromNullable(f.getStyle()).getOrElse(
            polygonDefaultStyle
          )}`
        )
      )
  );
  const layers = [
    new TileLayer({
      source: new TileWMS({
        url: "https://geoservices-urbis.irisnet.be/geoserver/ows",
        params: {
          LAYERS: "urbisFRGray",
          TILED: true,
          SRS: "EPSG:3857",
          VERSION: "1.1.1",
        },
      }),
    }),
    bvLayer,
    vectorLayer,
  ];

  const map = new Map({
    target: "map",
    layers,
    view,
  });

  // const header = document.getElementById('header')!;
  // const sidebar = document.getElementById('sidebar')!;
  const infoWrapper = document.getElementById("info")!;
  const legendWrapper = document.getElementById("legend_wrapper")!;
  const mapWrapper = document.getElementById("map")!;

  const selectFeatures = (catKey: PropKey, catValue: string) => {
    const selected = select.getFeatures();
    selected.clear();
    getFeaturesFromCat(catKey, catValue).map((f) => selected.push(f));
    featuresInfo(selected.getArray(), selectProject);
    if (categories().find((c) => c === catValue)) {
      set("category", catValue as Category);
    }
  };

  const selectProject = (id: number) => {
    const selected = select.getFeatures();
    selected.clear();
    getFeatureFromId(id).map((f) => selected.push(f));
    singleFeaturesInfo(selected.getArray());
    set("category", null);
  };

  const update = () => {
    updateLegend(legendWrapper, selectFeatures);
    vectorLayer.setStyle(vectorStyle(get("legend"), dataContext));
  };
  featureInfoFactory;

  mapWrapper.appendChild(renderSwitchLegend());

  const featuresInfo = featuresInfoFactory(infoWrapper, dataContext);
  const singleFeaturesInfo = featureInfoFactory(infoWrapper, dataContext);

  map.addInteraction(select);
  select.on("select", (e: SelectEvent) => {
    const features = e.target.getFeatures().getArray();
    const nb = features.length;
    if (nb > 1) {
      featuresInfo(features, selectProject);
    } else if (nb === 1) {
      singleFeaturesInfo(features);
    } else {
      updateInfo(infoWrapper);
      set("category", null);
    }
  });
  observe("legend", update);
  observe("legend", () => updateInfo(infoWrapper));
  observe("category", update);
  update();
  updateInfo(infoWrapper);
};

(window as any).brusseaubis = main;
