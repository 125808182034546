import { Feature } from "ol";
import { none } from "./option";
import { updateInfo } from "./index";
import {
  AcNode,
  DIV,
  SPANWithStyle,
  emptyElement,
  H2,
  SPAN,
  H3,
  DIVWithClick,
  UL,
  LI,
} from "./dom";
import { tr } from "./locale";
import { some } from "./option";
import { PropKey } from "./state";
import { tryNumber } from "./util";
import { renderType } from "./indivi";
import type { DataContext } from "./index";

const getSum = (features: Feature[], key: PropKey, dataContext: DataContext) =>
  features.reduce(
    (acc, f) =>
      dataContext
        .getFeatureProp(f, key)
        .map((val) => (typeof val === "number" ? acc + val : acc))
        .getOrElse(acc),
    0
  );

const renderSum = (
  features: Feature[],
  key: PropKey,
  dataContext: DataContext
): AcNode => {
  if (renderType[key] === "number") {
    const sumNumber = getSum(features, key, dataContext);
    return some(DIV("val", sumNumber.toLocaleString("fr-BE")));
  }
  return none as AcNode;
};

const renderAvg = (
  features: Feature[],
  key: PropKey,
  dataContext: DataContext
): AcNode => {
  if (renderType[key] === "number") {
    const avgNumber = getSum(features, key, dataContext) / features.length;
    return some(
      DIV("val", avgNumber.toLocaleString("fr-BE") + " " + tr("in_average"))
    );
  }
  return none as AcNode;
};

const getBVVolume = (bv: string, dataContext: DataContext) =>
  dataContext.getBVProp(bv, "vlbv");
const getBVvl10 = (bv: string, dataContext: DataContext) =>
  dataContext.getBVProp(bv, "vl10");

// const renderSums = (features: Feature[], keys: PropKey[]) => {
//   const pairs: AcNode[] = [];
//   keys.map((k) =>
//     pairs.push(DIV("value", DIV("key", tr(k)), renderSum(features, k)))
//   );
//   return DIV("info-group", DIV("info-keys", pairs));
// };

const graphBlock = (width: number, color: string) =>
  SPANWithStyle(
    "barchart-bar",
    `width: ${width}%;
            background-color: ${color};`
  );
const graphLabel = (width: number, color: string, label: number) =>
  width > 0
    ? SPANWithStyle(
        "barchart-label",
        `width: ${width}%;
        color: ${color};`,
        SPAN("label", `${label}%`)
      )
    : DIV("no label"); // TODO: fix this ;)

const legendElem = (color: string, label: string) =>
  DIV(
    "graph-legend",
    SPANWithStyle("color", `background-color:${color};`),
    SPAN("label", tr(label))
  );

const graph = (vl10: number, vlgiep: number, vlopp: number, vlbv: number) => {
  const vl10Perc = Math.round((vl10 / vlbv) * 100);
  const vlgiepPerc = Math.round((vlgiep / vlbv) * 100);
  const vloppPerc = Math.round((vlopp / vlbv) * 100);
  const other = Math.round(100 - vl10Perc - vlgiepPerc - vloppPerc);
  const scale = 1;
  // graph-block
  const blocks = DIV(
    "graph",
    graphBlock(vl10Perc, "#edab1e"),
    graphBlock(vlgiepPerc, "#ed521e"),
    graphBlock(vloppPerc, "#1e71ed"),
    graphBlock(other, "#c8ccd1")
  );

  //graph-labels
  const blocksLabels = DIV(
    "graph-labels",
    graphLabel(vl10Perc, "#edab1e", vl10Perc),
    graphLabel(vlgiepPerc, "#ed521e", vlgiepPerc),
    graphLabel(vloppPerc, "#1e71ed", vloppPerc),
    graphLabel(other, "#c8ccd1", other)
  );
  //legend
  const legend = DIV(
    "graph-legend-wrapper",
    legendElem("#edab1e", "vl10"),
    legendElem("#ed521e", "vlgiep"),
    legendElem("#1e71ed", "vlopp"),
    legendElem("#c8ccd1", "remaining")
  );

  return DIV(
    "graph-wrapper",
    H3("title", tr("vlbv-info")),
    blocksLabels,
    blocks,
    legend
  );
};

const renderFeatureList = (
  features: Feature[],
  selectProject: (id: number) => void,
  dataContext: DataContext
) =>
  DIV(
    "feature-list",
    H3("subtitle", tr("selected_projects")),
    UL(
      "list",
      ...features.map((f) =>
        dataContext.getFeatureID(f).map((id) =>
          LI(
            "feature-item",
            DIVWithClick(
              "",
              () => selectProject(id),
              dataContext.getFeatureName(f).getOrElse(id.toString())
            )
          )
        )
      )
    )
  );

export const featuresInfoFactory =
  (parent: Element, dataContext: DataContext) =>
  (features: Feature[], selectProject: (id: number) => void) => {
    emptyElement(parent);
    if (features.length >= 1) {
      const vlgiepSum = getSum(features, "vlgiep", dataContext);
      const vloppSum = getSum(features, "vlopp", dataContext);
      const bvOpt = dataContext.getFeatureBV(features[0]);
      const vl10Sum = bvOpt
        .chain((bv) =>
          getBVvl10(bv.toString(), dataContext).chain((vol) => tryNumber(vol))
        )
        .getOrElse(0);
      const vlbvSumOpt = bvOpt.chain((bv) =>
        getBVVolume(bv.toString(), dataContext).chain((vol) => tryNumber(vol))
      );
      const chart = vlbvSumOpt.map((vlbvSum) =>
        graph(vl10Sum, vlgiepSum, vloppSum, vlbvSum)
      );
      const summary = DIV(
        "summary",
        H2("summary-title", tr("aggregated_data"), ` (${features.length})`),
        // DIV("value", DIV("key", tr("nb_projects")), features.length),
        chart,
        DIV(
          "table",
          DIV(
            "kv",
            DIV("key", tr("bv")),
            bvOpt.map((bv) => DIV("value", tr(bv.toString())))
          ),
          DIV(
            "kv",
            DIV("key", tr("srf")),
            renderSum(features, "srf", dataContext)
          ),
          DIV(
            "kv",
            DIV("key", tr("srfimp")),
            renderSum(features, "srfimp", dataContext)
          ),
          DIV(
            "kv",
            DIV("key", tr("inf")),
            renderAvg(features, "inf", dataContext)
          )
        ),
        renderFeatureList(features, selectProject, dataContext)
        // renderSums(features, [
        //   "srf",
        //   "srfimp",
        // //   "bat",
        // //   "inf",
        // //   "vl20mm",
        // //   "vl20",
        // //   "vl100mm",
        // //   "vl100",
        // ])
      );
      parent.appendChild(summary);
      // } else if (features.length == 1) {
      //   featureInfoFactory(parent)(features);
    } else {
      updateInfo(parent);
    }
  };
